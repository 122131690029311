<template>
  <el-dialog
    center
    modal-centered
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="up-category-modal"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <CategoryForm v-model="categoryForm" :courses="courses" />
    <footer class="up-category-modal__footer">
      <el-button type="danger" @click="$emit('close')">Отмена</el-button>
      <el-button v-loading="loading" type="success" @click="editHandler">
        Сохранить
      </el-button>
    </footer>
  </el-dialog>
</template>

<script>
import CategoryForm from "@/components/methodist/categories/CategoryForm";

export default {
  name: "EditCategoryModal",
  props: {
    courses: Array,
    categoryData: Object,
  },
  components: { CategoryForm },
  data: () => ({
    loading: false,
    categoryForm: {
      name: "",
      parentCategoryId: "",
    },
  }),
  methods: {
    async editHandler() {
      if (this.loading) return;

      this.loading = true;

      try {
        const { data: coursesTree } = await this.$axios.post(
          "/constructor/category",
          {
            id: this.categoryData.id,
            ...this.categoryForm,
          }
        );

        this.$emit("update-tree", coursesTree);
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    "$attrs.visible"() {
      this.categoryForm.name = this.categoryData.name;
      this.categoryForm.parentCategoryId = this.categoryData.parentCategoryId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/categories/add-edit-category-modal.scss";
</style>