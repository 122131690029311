import { render, staticRenderFns } from "./EditCategoryModal.vue?vue&type=template&id=795f13dd&scoped=true"
import script from "./EditCategoryModal.vue?vue&type=script&lang=js"
export * from "./EditCategoryModal.vue?vue&type=script&lang=js"
import style0 from "./EditCategoryModal.vue?vue&type=style&index=0&id=795f13dd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795f13dd",
  null
  
)

export default component.exports