<template>
  <el-dialog
    center
    modal-centered
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="up-direction-modal"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <el-form>
      <el-form-item label="Введите название направления">
        <el-input v-model="direction" placeholder="Название направления" />
      </el-form-item>
    </el-form>
    <footer class="up-direction-modal__footer">
      <el-button type="danger" @click="$emit('close')">Отмена</el-button>
      <el-button v-loading="loading" type="success" @click="saveHandler">
        Сохранить
      </el-button>
    </footer>
  </el-dialog>
</template>

<script>
export default {
  name: "AddDirectionModal",
  data: () => ({
    direction: "",
    loading: false,
  }),
  methods: {
    async saveHandler() {
      if (this.loading) return;

      this.loading = true;

      try {
        const { data: coursesTree } = await this.$axios.post(
          "/constructor/category",
          {
            name: this.direction,
          }
        );

        this.$emit("save", coursesTree);
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "$attrs.visible"() {
      this.direction = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/categories/add-edit-direction-modal.scss";
</style>