<template>
  <div class="up-categories-page">
    <h1 class="up-main-title">Категории</h1>
    <template v-if="!loading">
      <AppCollapse v-for="(direction, i) in coursesTree" :key="direction.id">
        <template slot="title">
          {{ direction.name }}
          <DirectionDropdown
            :direction="direction"
            @open-edit-modal="openEditDirectionModal(i)"
            @delete-direction="deleteDirection"
          />
        </template>
        <p
          v-for="(category, idx) in direction.childs"
          :key="`category-${category.id}`"
          class="up-categories-page__text"
        >
          {{ category.name }}
          ({{ category.courses ? category.courses.length : 0 }})
          <CategoryDropdown
            :category="category"
            @open-edit-modal="openEditCategoryModal(direction.id, category)"
            @delete-category="deleteCategory(direction, idx, category.id)"
            @open-unite-modal="openUniteCategoriesModal"
          />
        </p>
      </AppCollapse>
      <footer class="up-categories-page__footer">
        <el-button type="primary" @click="directionModalVisible = true">
          Добавить направление
        </el-button>
        <el-button type="primary" @click="categoryModalVisible = true">
          Добавить категорию
        </el-button>
      </footer>
      <AddDirectionModal
        :visible.sync="directionModalVisible"
        title="Добавление нового направления"
        @close="directionModalVisible = false"
        @save="addNewItem"
      />
      <EditDirectionModal
        :visible.sync="editDirectionModalVisible"
        title="Редактирование направления"
        :old-direction="coursesTree[editableDirectionIdx]"
        @close="editDirectionModalVisible = false"
        @save="editDirectionHandler"
      />
      <AddCategoryModal
        :visible.sync="categoryModalVisible"
        :courses="coursesTree"
        title="Добавление новой категории"
        @close="categoryModalVisible = false"
        @save="addNewItem"
      />
      <EditCategoryModal
        :visible.sync="editCategoryModalVisible"
        :courses="coursesTree"
        :category-data="editableCategoryData"
        title="Редактирование категории"
        @close="editCategoryModalVisible = false"
        @update-tree="updateCoursesTree"
      />
      <UniteCategoriesModal
        :visible.sync="uniteCategoriesModalVisible"
        :courses-tree="coursesTree"
        :category-data="uniteCategory"
        title="Объединение категорий"
        @close="uniteCategoriesModalVisible = false"
        @update-tree="updateCoursesTree"
      />
    </template>
    <p v-if="coursesTree.length === 0 && !loading" class="up-empty-list-text">
      Список пуст
    </p>
  </div>
</template>

<script>
import AppCollapse from "@/components/AppCollapse";
import AddDirectionModal from "@/components/methodist/categories/AddDirectionModal";
import EditDirectionModal from "@/components/methodist/categories/EditDirectionModal";
import AddCategoryModal from "@/components/methodist/categories/AddCategoryModal";
import EditCategoryModal from "@/components/methodist/categories/EditCategoryModal";
import UniteCategoriesModal from "@/components/methodist/categories/UniteCategoriesModal";
import DirectionDropdown from "@/components/methodist/categories/DirectionDropdown";
import CategoryDropdown from "@/components/methodist/categories/CategoryDropdown";

import showErrorMessage from "@/mixins/showErrorMessage.mixin";

import { mapActions } from "vuex";

export default {
  name: "CoursesCategory",
  mixins: [showErrorMessage],
  components: {
    AppCollapse,
    AddDirectionModal,
    EditDirectionModal,
    AddCategoryModal,
    EditCategoryModal,
    UniteCategoriesModal,
    DirectionDropdown,
    CategoryDropdown,
  },
  data: () => ({
    coursesTree: [],
    loading: true,
    directionModalVisible: false,
    editDirectionModalVisible: false,
    editableDirectionIdx: 0,
    categoryModalVisible: false,
    editCategoryModalVisible: false,
    editableCategoryData: {},
    uniteCategoriesModalVisible: false,
    uniteCategory: {},
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
    openUniteCategoriesModal(category) {
      this.uniteCategoriesModalVisible = true;
      this.uniteCategory = category;
    },
    openEditDirectionModal(directionIdx) {
      this.editableDirectionIdx = directionIdx;
      this.editDirectionModalVisible = true;
    },
    addNewItem(newTree) {
      this.coursesTree = newTree;
      this.directionModalVisible = false;
      this.categoryModalVisible = false;
    },
    addNewCaterory(newTree) {
      this.coursesTree = newTree;
    },
    editDirectionHandler(newName) {
      this.editDirectionModalVisible = false;
      this.coursesTree[this.editableDirectionIdx].name = newName;
    },
    openEditCategoryModal(parentCategoryId, category) {
      this.editCategoryModalVisible = true;
      this.editableCategoryData = {
        parentCategoryId,
        id: category.id,
        name: category.name,
      };
    },
    updateCoursesTree(newTree) {
      this.coursesTree = newTree;

      this.editCategoryModalVisible = false;
      this.uniteCategoriesModalVisible = false;

      this.$message({
        message: "Поздравляем! Данные успешно обновлены.",
        type: "success",
      });
    },
    deleteDirection(courseId) {
      this.deleteByIdHandler(courseId, () => {
        this.coursesTree = this.coursesTree.filter((c) => c.id !== courseId);
      });
    },
    deleteCategory(direction, categoryNumber, id) {
      this.deleteByIdHandler(id, () => {
        direction.childs.splice(categoryNumber, 1);
      });
    },
    async deleteByIdHandler(id, callback) {
      try {
        await this.$axios.delete("/constructor/category", {
          data: { id },
        });

        this.$message({
          message: "Поздравляем! Данные успешно удалены.",
          type: "success",
        });

        callback();
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    async fetchTree() {
      this.$nprogress.start();

      try {
        const res = await this.$axios.get("/constructor/treeFull");

        this.coursesTree = res.data;
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
  },
  created() {
    this.setNavigationText("Создание и редактирование направлений и категорий");

    this.fetchTree();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/methodist/categories.scss";
</style>