<template>
  <el-form>
    <el-form-item
      label="Выберите направление, к которому будет привязана категория"
    >
      <el-select v-if="courses.length > 0" v-model="form.parentCategoryId">
        <el-option
          v-for="c in courses"
          :key="c.id"
          :label="c.name"
          :value="c.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Введите название категории">
      <el-input v-model="form.name" placeholder="Название категории" />
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "CategoryForm",
  props: {
    value: null,
    courses: Array,
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/categories/category-form.scss";
</style>