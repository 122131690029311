<template>
  <el-dialog
    center
    modal-centered
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="up-unite-modal"
    v-bind="$attrs"
    v-on="$listeners"
    @open="joinCategoryId = null"
  >
    <p class="up-unite-modal__text">
      Вы собираетесь объединить категорию: {{ categoryData.name }}. Все курсы
      данной категории будут перемещены в ту категорию, которую Вы выберите
      ниже.
    </p>
    <el-select v-model="joinCategoryId">
      <template v-for="direction in coursesTree">
        <el-option-group
          v-if="direction.childs"
          :key="direction.id"
          :label="direction.name"
        >
          <el-option
            v-for="category in direction.childs"
            :key="category.id"
            :label="category.name"
            :value="category.id"
          >
          </el-option>
        </el-option-group>
      </template>
    </el-select>
    <footer class="up-unite-modal__footer">
      <el-button type="danger" @click="$emit('close')">Отмена</el-button>
      <el-button v-loading="loading" type="success" @click="uniteHandler">
        Сохранить
      </el-button>
    </footer>
  </el-dialog>
</template>

<script>
export default {
  name: "UniteCategoriesModal",
  data: () => ({
    loading: false,
    joinCategoryId: null,
  }),
  props: {
    coursesTree: Array,
    categoryData: Object,
  },
  methods: {
    async uniteHandler() {
      if (this.loading) return;

      this.loading = true;

      try {
        const { data: coursesTree } = await this.$axios.post(
          "/constructor/category",
          {
            id: this.categoryData.id,
            joinToCategoryId: this.joinCategoryId,
          }
        );

        this.$emit("update-tree", coursesTree);
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/categories/unite-categories-modal.scss";
</style>