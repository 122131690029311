<template>
  <el-dropdown trigger="click" @command="categoryDropdownAction">
    <span class="el-dropdown-link"><i class="el-icon-more" /></span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item class="is-blue" icon="el-icon-edit" command="edit">
        Редактировать
      </el-dropdown-item>
      <el-dropdown-item
        v-if="!category.courses || category.courses.length === 0"
        class="is-red"
        icon="el-icon-delete"
        command="remove"
      >
        Удалить
      </el-dropdown-item>
      <el-dropdown-item class="is-blue" icon="el-icon-s-unfold" command="unite">
        Объединить курсы категорий
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "CategoryDropdown",
  props: {
    category: Object,
  },
  methods: {
    categoryDropdownAction(type) {
      if (type === "edit") {
        this.$emit("open-edit-modal");
        return;
      } else if (type === "remove") {
        this.$emit("delete-category");
        return;
      }

      this.$emit("open-unite-modal", this.category);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/categories/dropdown.scss";
</style>