<template>
  <el-dropdown trigger="click" @command="directionDropdownAction">
    <span class="el-dropdown-link"><i class="el-icon-more" /></span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item class="is-blue" icon="el-icon-edit" command="edit">
        Редактировать
      </el-dropdown-item>
      <el-dropdown-item
        v-if="directionCanBeDeleted"
        class="is-red"
        icon="el-icon-delete"
        command="remove"
      >
        Удалить
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "DirectionDropdown",
  props: {
    direction: Object,
  },
  computed: {
    directionCanBeDeleted() {
      if (!this.direction.childs) return true;

      let canBeDeleted = true;

      this.direction.childs.forEach((c) => {
        if (!c.courses || c.courses.length === 0) return;

        canBeDeleted = false;
      });

      return canBeDeleted;
    },
  },
  methods: {
    directionDropdownAction(type) {
      if (type === "edit") {
        this.$emit("open-edit-modal");
        return;
      }

      this.$emit("delete-direction", this.direction.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/categories/dropdown.scss";
</style>