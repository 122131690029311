<template>
  <el-dialog
    center
    modal-centered
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="up-category-modal"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <CategoryForm v-model="categoryForm" :courses="courses" />
    <footer class="up-category-modal__footer">
      <el-button type="danger" @click="$emit('close')">Отмена</el-button>
      <el-button v-loading="loading" type="success" @click="saveHandler">
        Сохранить
      </el-button>
    </footer>
  </el-dialog>
</template>

<script>
import CategoryForm from "@/components/methodist/categories/CategoryForm";

export default {
  name: "AddCategoryModal",
  props: {
    courses: Array,
  },
  components: { CategoryForm },
  data: () => ({
    loading: false,
    categoryForm: {
      name: "",
      parentCategoryId: "",
    },
  }),
  methods: {
    async saveHandler() {
      if (this.loading) return;

      this.loading = true;

      try {
        const { data: coursesTree } = await this.$axios.post(
          "/constructor/category",
          this.categoryForm
        );

        this.$emit("save", coursesTree);
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "$attrs.visible"() {
      this.categoryForm = {
        name: "",
        parentCategoryId: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/categories/add-edit-category-modal.scss";
</style>